var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"font-wight-medium"},[_vm._v("\n    "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.title'))+"\n  ")]),_vm._v(" "),(_vm.partialUser)?_c('div',[_c('div',{staticClass:"d-flex mt-3 align-center"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.desc.private'))+"\n      ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","size":18}},'v-icon',attrs,false),on),[_vm._v("\n            mdi-information-outline\n          ")])]}}],null,false,2819336589)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.tooltip')))])])],1),_vm._v(" "),_c('ul',{staticClass:"mt-2"},_vm._l((_vm.partialUserRemainingSecurities),function(security,index){return _c('li',{key:index},[_vm._v("\n        "+_vm._s(_vm.$t(("settings.knowledgeAndExperience.investorStatus.partialUser." + security)))+"\n      ")])}),0),_vm._v(" "),_c('div',{staticClass:"d-flex mt-3 align-center"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.desc.prok'))+"\n      ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","size":18}},'v-icon',attrs,false),on),[_vm._v("\n            mdi-information-outline\n          ")])]}}],null,false,2819336589)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.tooltip')))])])],1),_vm._v(" "),_c('ul',{staticClass:"mt-2"},_vm._l((_vm.investorClassification.professionalSecurities),function(security,index){return _c('li',{key:index},[_vm._v("\n        "+_vm._s(_vm.$t(("settings.knowledgeAndExperience.investorStatus.partialUser." + security)))+"\n      ")])}),0)]):_c('div',[_c('div',{staticClass:"d-flex mt-3 align-center"},[(_vm.privateUser)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.desc.private'))+"\n      ")]):_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.desc.prok'))+"\n      ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","size":18}},'v-icon',attrs,false),on),[_vm._v("\n            mdi-information-outline\n          ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.tooltip')))])])],1),_vm._v(" "),_c('div',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.list'))}})]),_vm._v(" "),(_vm.privateUser)?_c('v-btn',{staticClass:"black--text mt-5 mobile-btn",attrs:{"color":"primary","disabled":_vm.professionalSecuritiesOptions.length < 1},on:{"click":function($event){return _vm.$router.push('/settings/investor-classification')}}},[_vm._v("\n    "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.btn.prok'))+"\n  ")]):_c('div',{staticClass:"d-flex mt-5"},[_c('v-btn',{staticClass:"black--text mobile-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('downgrade')}}},[_vm._v("\n      "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.btn.private'))+"\n    ")]),_vm._v(" "),_c('v-btn',{staticClass:"black--text ml-2 mobile-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/settings/investor-classification')}}},[_vm._v("\n      "+_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.btn.reclassify'))+"\n    ")])],1),_vm._v(" "),_c('p',{staticClass:"mt-5 grey--text small-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.footer.partOne')))]),_c('br'),_vm._v(" "),_c('a',{staticClass:"grey--text text-decoration-underline",attrs:{"download":"info_sheet_prok.pdf"},on:{"click":_vm.download}},[_vm._v(_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.footer.link')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('settings.knowledgeAndExperience.investorStatus.footer.partTwo')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }