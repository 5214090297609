<template>
  <div>
    <h3 class="font-wight-medium">
      {{ $t('settings.knowledgeAndExperience.investorStatus.title') }}
    </h3>
    <div v-if="partialUser">
      <div class="d-flex mt-3 align-center">
        <span>
          {{ $t('settings.knowledgeAndExperience.investorStatus.desc.private') }}
        </span>
        <v-tooltip top color="backgroundDark" :max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              dark
              v-bind="attrs"
              v-on="on"
              :size="18"
              class="ml-2"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ $t('settings.knowledgeAndExperience.investorStatus.tooltip') }}</span>
        </v-tooltip>
      </div>
      <ul class="mt-2">
        <li v-for="(security, index) in partialUserRemainingSecurities" :key="index">
          {{ $t(`settings.knowledgeAndExperience.investorStatus.partialUser.${security}`) }}
        </li>
      </ul>
      <div class="d-flex mt-3 align-center">
        <span>
          {{ $t('settings.knowledgeAndExperience.investorStatus.desc.prok') }}
        </span>
        <v-tooltip top color="backgroundDark" :max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              dark
              v-bind="attrs"
              v-on="on"
              :size="18"
              class="ml-2"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ $t('settings.knowledgeAndExperience.investorStatus.tooltip') }}</span>
        </v-tooltip>
      </div>
      <ul class="mt-2">
        <li v-for="(security, index) in investorClassification.professionalSecurities" :key="index">
          {{ $t(`settings.knowledgeAndExperience.investorStatus.partialUser.${security}`) }}
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="d-flex mt-3 align-center">
        <span v-if="privateUser">
          {{ $t('settings.knowledgeAndExperience.investorStatus.desc.private') }}
        </span>
        <span v-else>
          {{ $t('settings.knowledgeAndExperience.investorStatus.desc.prok') }}
        </span>
        <v-tooltip top color="backgroundDark" :max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              dark
              v-bind="attrs"
              v-on="on"
              :size="18"
              class="ml-2"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ $t('settings.knowledgeAndExperience.investorStatus.tooltip') }}</span>
        </v-tooltip>
      </div>
      <div class="mt-2" v-html="$t('settings.knowledgeAndExperience.investorStatus.list')" />
    </div>
    <v-btn
      v-if="privateUser"
      color="primary"
      class="black--text mt-5 mobile-btn"
      :disabled="professionalSecuritiesOptions.length < 1"
      @click="$router.push('/settings/investor-classification')"
    >
      {{ $t('settings.knowledgeAndExperience.investorStatus.btn.prok') }}
    </v-btn>
    <div v-else class="d-flex mt-5">
      <v-btn
        color="primary"
        class="black--text mobile-btn"
        @click="$emit('downgrade')"
      >
        {{ $t('settings.knowledgeAndExperience.investorStatus.btn.private') }}
      </v-btn>
      <v-btn
        color="primary"
        class="black--text ml-2 mobile-btn"
        @click="$router.push('/settings/investor-classification')"
      >
        {{ $t('settings.knowledgeAndExperience.investorStatus.btn.reclassify') }}
      </v-btn>
    </div>
    <p class="mt-5 grey--text small-text">
      <span>{{ $t('settings.knowledgeAndExperience.investorStatus.footer.partOne') }}</span><br>
      <a
        @click="download"
        download="info_sheet_prok.pdf"
        class="grey--text text-decoration-underline"
      >{{ $t('settings.knowledgeAndExperience.investorStatus.footer.link') }}</a>
      <span>{{ $t('settings.knowledgeAndExperience.investorStatus.footer.partTwo') }}</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDownloadUrl } from '@/api/documents';

export default {
  name: 'InvestorStatus',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      downloadUrl: null,
      allSecurities: ['InvestmentFund', 'GovernmentBonds', 'Bond', 'Share', 'CryptoCurrency', 'UnsecuredJuniorDebt'],
    };
  },
  computed: {
    ...mapGetters('user', [
      'investorClassification',
      'investmentSurvey',
    ]),
    privateUser() {
      return this.investorClassification.investorClassification === 'Private';
    },
    partialUser() {
      return this.investorClassification.investorClassification === 'Partial';
    },
    professionalUser() {
      return this.investorClassification.investorClassification === 'Professional';
    },
    partialUserRemainingSecurities() {
      const securities = [];
      this.allSecurities.forEach((security) => {
        if (!this.investorClassification.professionalSecurities.includes(security)) {
          securities.push(security);
        }
      });
      return securities;
    },
    professionalSecuritiesOptions() {
      const options = [];
      Object.values(this.data.investmentSurvey.investmentExperience).forEach((type) => {
        if (type[0].experience === 'GreaterThan5Years') {
          options.push(type[0].type);
        }
        if (type[1].experience === 'GreaterThan5Years') {
          options.push(type[1].type);
        }
      });
      return options;
    },
  },
  methods: {
    documentTitle(documentTemplateId, documentKey) {
      const translationKey = documentTemplateId.replace(/_/g, '.');
      const title = `${translationKey}.${documentKey}.title`;
      return title;
    },
    async download() {
      const input = {
        documentTemplateId: 'consent_declaration_prok',
        documentKey: 'information_prok.pdf',
      };
      const response = await getDownloadUrl(input);
      const link = document.createElement('a');
      link.download = `${this.$t(this.documentTitle('consent_declaration_prok', 'information_prok.pdf'))}.pdf`;
      link.href = response.pdfInBase64;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  font-size: 12px;
}
@media (max-width: 600px) {
  .mobile-btn {
    font-size: 11px !important;
  }
}
</style>
